import React from 'react'

const styles = {
  container: {
    height: '100px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-50px',
    marginLeft: '-200px',
    width: '400px'
  },
  title: {
    fontFamily: 'PT Serif',
    fontHeight: 52,
    fontSize: 48,
    fontWeight: '700',
    textAlign: 'center'
  },
  tagline:{
    fontFamily: 'Nanum Gothic',
    fontHeight: 32,
    fontSize: 24,
    fontWeight: '400',
    textAlign: 'center'
  }
};

export default function Home() {
  return (
    <div style={styles.container}> 
      <div style={styles.title}>Daniel Mahadi</div>
      <div style={styles.tagline}>Others code, I craft for perfection</div>
    </div>
  );
}
